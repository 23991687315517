import request from '@/config/axios'
import { formatTime } from '@/utils'

export const getListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/classRollCallTimes', params })

  return {
    data: res
  }
}

export const saveApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/classRollCallTimes/${data.id}`, data })
  } else {
    return await request.post({ url: '/classRollCallTimes', data })
  }
}


export const delApi = async (ids: string[] | number[]): Promise<IResponse> => {
  return await request.delete({ url: `/classRollCallTimes`, params: { ids } })
}


export default {
  getListApi,
  saveApi,
  delApi,
}
